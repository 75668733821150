




























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CircularProgress extends Vue {
  @Prop({ required: true })
  size!: number;

  @Prop({ required: true })
  progress!: number;

  @Prop({ required: true })
  circleOneStroke!: string;

  @Prop({ required: true })
  circleTwoStroke!: string;

  @Prop({ required: true })
  strokeWidth!: number;

  @Prop({ required: true })
  color!: string;

  center = this.size / 2;
  radius = this.size / 2 - this.strokeWidth / 2;
  circumference = 2 * Math.PI * this.radius;

  get offset(): number {
    return ((100 - this.progress) / 100) * this.circumference;
  }
}
