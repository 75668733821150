

















import { Component, Vue } from "vue-property-decorator";
import ChangePredictions from "./changePredictions.vue";
import AddCardModal from "./AddCardModal.vue";
import PredictionModal from "./PredictionModal.vue";
import ModalAskTitle from "@/components/modal/ModalAskTitle.vue";
import AddVersionModal from "@/components/modal/versions/AddVersionModal.vue";
import ChangeVersionsModal from "@/components/modal/versions/ChangeVersionsModal.vue";

@Component({
  components: {
    ChangeVersionsModal,
    AddVersionModal,
    ChangePredictions,
    AddCardModal,
    PredictionModal,
    ModalAskTitle,
  },
})
export default class extends Vue {
  get addVersionModalOpen(): boolean {
    return this.$store.getters["modals/addVersionModal"];
  }

  get changeVersionsModalOpen(): boolean {
    return this.$store.getters["modals/changeVersionsModal"];
  }

  get modalTitleOpen(): boolean {
    return this.$store.getters["cards/modalTitleOpen"];
  }

  get addPredictionModalOpen(): boolean {
    return this.$store.getters["modals/addPredictionModal"];
  }

  get addCardModalOpen(): boolean {
    return this.$store.getters["modals/addCardModal"];
  }

  get changePredictionsModalOpen(): boolean {
    return this.$store.getters["modals/changePredictionModal"];
  }
}
