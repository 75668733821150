
































import { Component, Vue } from "vue-property-decorator";
import Modal from "../Modal.vue";
import { IVersion } from "@/store/generalModule";
import InputGroup from "@/components/form/InputGroup.vue";
import { validationMixin } from "vuelidate";

@Component({
  components: { InputGroup, Modal },
  mixins: [validationMixin],
  validations: {},
})
export default class ChangeVersionsModal extends Vue {
  get versions(): IVersion[] {
    return this.$store.getters["general/versions"];
  }

  deleteVersion(id: string): void {
    this.$store.dispatch("general/deleteVersion", id);
  }

  closeModal(): void {
    this.$store.commit("modals/TOGGLE_CHANGE_VERSIONS_MODAL", false);
  }
}
