import Vue from "vue";

export default class extends Vue {
  parseComma(numberToSearch: string): string {
    let number = numberToSearch;
    const indexOfComma = numberToSearch.indexOf(",");
    if (indexOfComma > 0) {
      number = numberToSearch.replace(",", ".");
    }

    return number;
  }
}
