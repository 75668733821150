<template>
  <form>
    <label class="block font-bold mb-2" for="text-area">Notes</label>
    <textarea
      id="text-area"
      class="border-none p-3"
      cols="30"
      name="notes"
      rows="30"
    ></textarea>
  </form>
</template>

<script>
export default {
  name: "NotesArea",
};
</script>

<style lang="scss" scoped>
textarea {
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-lg;

  outline: none;

  max-width: 20rem;
  min-width: 20rem;
  max-height: 32rem;
  min-height: 32rem;
}
</style>
