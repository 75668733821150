






































































































































import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { between, minLength, required } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    name: {
      minLength: minLength(1),
      required,
    },
    percentage: {
      required,
      between: between(0, 100),
    },
  },
})
export default class PredictionModal extends Vue {
  private name = "";
  private percentage = 0;

  handleSubmit(): void {
    if (this.percentage <= 100 && this.percentage >= 0 && this.name) {
      this.$store.dispatch("cards/addUserPrediction", {
        name: this.name,
        percentage: this.percentage / 100,
      });
      this.$store.commit("modals/TOGGLE_ADD_PREDICTION_MODAL", false);

      this.resetForm();
    }
  }

  resetForm(): void {
    this.name = "";
    this.percentage = 0;
  }

  onCancel(): void {
    this.$emit("cancelClick");
    this.$v.$reset();
    this.resetForm();
  }
}
