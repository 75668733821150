


















































import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import Input from "@/components/form/Input.vue";
import Modal from "@/components/modal/Modal.vue";
import InputGroup from "@/components/form/InputGroup.vue";
import { required } from "vuelidate/lib/validators";
import { IPrediction } from "@/types/Prediction";
import { IItem } from "@/types/Item";

@Component({
  components: {
    InputGroup,
    Modal,
    Input,
  },

  validations: {
    versionName: {
      required,
    },
  },

  mixins: [validationMixin],
})
export default class extends Vue {
  versionName = "";
  keepCards = false;
  keepPredictions = false;

  closeModal(): void {
    this.$store.commit("modals/TOGGLE_ADD_VERSION_MODAL", false);
  }

  resetForm(): void {
    this.versionName = "";
    this.keepCards = false;
    this.keepPredictions = false;
  }

  submitHandler(): void {
    if (!this.$v.$invalid) {
      let versionObject: {
        name: string;
        cards: IItem[];
        predictions: IPrediction[];
      } = {
        name: this.versionName,
        cards: [],
        predictions: [],
      };
      const cloneCards: IItem[] = [...this.$store.getters["cards/cards"]];
      const clonePredictions: IPrediction[] = [
        ...this.$store.getters["cards/predictions"],
      ];

      if (this.keepCards) {
        versionObject = {
          ...versionObject,
          cards: cloneCards,
        };
      }

      if (this.keepPredictions) {
        versionObject = {
          ...versionObject,
          predictions: clonePredictions,
        };
      }

      this.$store.dispatch("general/addNewVersion", versionObject);

      this.resetForm();
      this.$v.$reset();
      this.closeModal();
    }
  }
}
