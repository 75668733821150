
































































































































































import { IItem } from "@/types/Item";
import ClickOutside from "vue-click-outside";
import Vue from "vue";

export default Vue.extend({
  props: {
    item: Object,
  },

  directives: {
    ClickOutside,
  },

  data: function () {
    return {
      isEditing: false,
      isEditingPercentage: false,
      cardTitle: "",
      inputPercentage: null,
      interval: 0 as unknown as ReturnType<typeof setInterval>,
    };
  },

  methods: {
    changeCard(index: number) {
      if (this.cardTitle) {
        this.$store.dispatch("cards/changeCardTitle", {
          index,
          title: this.cardTitle,
        });
      }
    },

    changeTitle(event: { target: HTMLInputElement }) {
      this.cardTitle = event.target.value;
    },

    changeCardPercentage(index: number) {
      this.$store.dispatch("cards/changeCardPercentage", {
        index,
        percentage: this.item.percentage,
      });
    },

    increment(): void {
      if (this.item.percentage < 1) {
        this.item.percentage += 0.01;
      }
    },

    decrement(): void {
      if (this.item.percentage > 0) {
        this.item.percentage -= 0.01;
      }
    },

    changeCardPercentagePlus(): void {
      if (this.item.percentage < 1) {
        this.increment();
        this.interval = setInterval(this.increment, 150);
      }
    },

    changeCardPercentageMinus(): void {
      if (this.item.percentage > 0) {
        this.decrement();
        this.interval = setInterval(this.decrement, 150);
      }
    },

    mouseLeave(index: number): void {
      clearInterval(this.interval);
      this.changeCardPercentage(index);
    },

    deleteCard(): void {
      this.$store.dispatch("cards/deleteCard", this.item.index);
    },

    handlePercentageChange(event: { target: HTMLInputElement }): void {
      this.item.percentage = Number(event.target.value) / 100;
    },

    handleTitleChange(): void {
      this.isEditing = false;
      this.$store.dispatch("cards/changeCardTitle", this.item.title);
    },
  },
  computed: {
    percentage(): string {
      return `${Math.round(this.item.percentage * 100 * 100) / 100}%`;
    },
    cards(): IItem[] {
      return this.$store.getters["cards/cards"];
    },

    cardBackground() {
      switch (true) {
        case this.item.result >= 70:
          return "color__1";
        case this.item.result >= 40:
          return "color__2";
        case this.item.result >= 25:
          return "color__3";
        case this.item.result >= 1:
          return "color__4";
        default:
          return "color__5";
      }
    },
  },
});
