



























































































import { Component, Vue } from "vue-property-decorator";
import Modal from "@/components/modal/Modal.vue";
import { validationMixin } from "vuelidate";
import { IPrediction } from "@/types/Prediction";

@Component({
  components: { Modal },
  mixins: [validationMixin],
  validations: {},
})
export default class extends Vue {
  addPredictionClicked = false;

  get predictions(): IPrediction[] {
    return this.$store.getters["cards/predictions"];
  }

  onAddPrediction(): void {
    this.$store.dispatch("cards/addUserPrediction", {
      name: "",
      percentage: 0,
    });

    this.addPredictionClicked = true;
  }

  updateCards(): void {
    this.$store.dispatch("cards/updatePredictions");
    this.closeModal();
  }

  userChanged(): void {
    if (!this.addPredictionClicked) this.addPredictionClicked = true;
  }

  closeModal(): void {
    this.$store.commit("cards/REMOVE_EMPTY_PREDICTIONS");
    this.$store.commit("modals/TOGGLE_CHANGE_PREDICTION_MODAL", false);
  }
}
