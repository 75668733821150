




































import { Component, Prop, Vue } from "vue-property-decorator";
import { IVersion } from "@/store/generalModule";
import ClickOutside from "vue-click-outside";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class extends Vue {
  open = false;
  @Prop({ required: true })
  private readonly currentVersion!: IVersion;

  get versions(): IVersion[] {
    return this.$store.getters["general/versions"].filter(
      (version: IVersion) => version.id !== this.currentVersion.id
    );
  }

  get isEmpty(): boolean {
    return Object.keys(this.currentVersion).length === 0;
  }

  changeVersion(id: string) {
    this.$store.dispatch("general/changeCurrentVersion", id);
  }

  closeMenu(): void {
    this.open = false;
  }

  handleMenu(): void {
    if (this.versions.length) {
      this.open = !this.open;
    }
  }
}
