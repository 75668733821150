





























































































































import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { between, required } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    percentage: {
      required,
      between: between(0, 100),
    },
    title: {
      required,
    },
  },
})
export default class AddCardModal extends Vue {
  private percentage = 0;
  private title = "";

  handleSubmit(): void {
    if (!this.$v.$invalid) {
      this.$store.dispatch("cards/addCard", {
        cardTitle: this.title,
        cardPercentage: this.percentage,
      });
      this.$store.commit("modals/TOGGLE_ADD_CARD_MODAL", false);

      this.resetForm();
    }
  }

  resetForm(): void {
    this.percentage = 0;
    this.title = "";
    this.$v.$reset();
  }

  onCancel(): void {
    this.$emit("cancelClick");
    this.resetForm();
  }
}
