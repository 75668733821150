

































































import { IPrediction } from "@/types/Prediction";
import StringMixin from "@/mixins/StringMixin";
import Component from "vue-class-component";
import CircularProgress from "@/components/shared/CircularProgress.vue";
import { IItem } from "@/types/Item";

@Component({
  components: { CircularProgress },
})
export default class Predictions extends StringMixin {
  width: number = window.screen.width;

  get predictions(): IPrediction[] {
    return this.$store.getters["cards/predictions"];
  }

  get cards(): IItem[] {
    return this.$store.getters["cards/cards"];
  }

  get lastCard(): IItem {
    return this.$store.getters["cards/lastCard"];
  }

  get progressCircleColor(): string {
    switch (true) {
      case this.lastCard.result >= 70:
        return "rgb(177, 230, 153)";
      case this.lastCard.result >= 40:
        return "rgb(244, 233, 194)";
      case this.lastCard.result >= 25:
        return "rgb(244, 218, 194)";
      case this.lastCard.result >= 1:
        return "rgb(231, 140, 140)";
      default:
        return "rgb(200, 52, 52)";
    }
  }

  predictionPercentage(prediction: IPrediction): string {
    return `${Math.round(prediction.percentage * 100 * 100) / 100}%`;
  }
}
