







































































import { Component, Prop } from "vue-property-decorator";
import StringMixin from "@/mixins/StringMixin";

@Component
export default class extends StringMixin {
  @Prop({ required: false })
  isInvalid?: boolean;

  @Prop({ required: false, default: false })
  justifyBetween?: boolean;

  @Prop({ required: true })
  title!: string;

  onCancelClick(): void {
    this.$emit("onCancel");
  }
}
