






























































































import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import CardList from "@/components/CardList.vue";
import Predictions from "@/components/Predictions.vue";
import { IItem } from "@/types/Item";
import draggable from "vuedraggable";
import NotesArea from "@/components/NotesArea.vue";
import Wrapper from "@/components/shared/Wrapper.vue";
import ModalWrapper from "@/components/modal/ModalWrapper.vue";
import { IVersion } from "@/store/generalModule";
import VersionTag from "@/components/VersionTag.vue";

@Component({
  components: {
    VersionTag,
    ModalWrapper,
    Wrapper,
    NotesArea,
    Card,
    CardList,
    Predictions,
    draggable,
  },
})
export default class User extends Vue {
  public isAddingCard = false;
  public isEditingTitle = false;
  public title = "";

  get cards(): IItem[] {
    return this.$store.getters["cards/cards"];
  }

  set cards(value: IItem[]) {
    this.$store.dispatch("cards/updateCardsOrder", value);
  }

  get projectTitle(): string {
    return this.$store.getters["cards/projectTitle"];
  }

  get currentVersion(): IVersion {
    return this.$store.getters["general/currentVersion"];
  }

  mounted(): void {
    this.title = localStorage.getItem("PROJECT_TITLE") as string;
  }

  onChangeTitle(e: { target: HTMLInputElement }): void {
    this.title = e.target.value;
  }

  editTitle(): void {
    this.isEditingTitle = !this.isEditingTitle;
  }

  changeTitle(): void {
    this.isEditingTitle = false;
    if (this.title !== "") {
      this.$store.commit("cards/SET_PROJECT_TITLE", this.title);
    }
  }
}
