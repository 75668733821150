






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop({ required: true })
  private readonly label!: string;

  @Prop({ required: true })
  private readonly labelText!: string;

  @Prop({ required: true })
  private readonly inputType!: string;

  @Prop({ required: false })
  private readonly placeholder?: string;
}
